import React from 'react'

function FiltroOpcion({filtro, option, onFilterChange}) {
        
    const handleFilterChange = event => {
        if(onFilterChange){
            onFilterChange(event.target.checked, filtro, option.value)
        }
    } 

    return (
        <div className="">
        <label className="inline-flex items-center">
        <input type="checkbox" className="form-checkbox transform scale-150"
        onChange={handleFilterChange}
        />
        <span className="ml-2 text-sm font-light text-gray-500">{option.nombre}</span>
        </label>
    </div>
    )
}

export default FiltroOpcion
