import * as React from "react"
import{Skeleton} from "@material-ui/lab"


const ContentSkeleton = ( ) => 
{
    return(<div>

    <div style={{height:240 , width:"100%"}} className="mb-8">
        <Skeleton className="mr-5" variant="rect" height={240}></Skeleton>          
    </div>

    <div className ="mb-8">
        <Skeleton variant="text" width={345}  ></Skeleton>
    </div>
    <div className="flex mb-8">
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
    </div>
    
    <div className ="mb-8">
        <Skeleton variant="text" width={345}  ></Skeleton>
    </div>
    <div className="flex mb-8">
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
    </div>
    

    <div className ="mb-8">
        <Skeleton variant="text" width={345}  ></Skeleton>
    </div>
    <div className="flex mb-2">
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
      <Skeleton className="mr-5" variant="rect" width={345} height={200}></Skeleton>
    </div>
    


    </div>)
}

export default ContentSkeleton