import React from 'react';
import { Modal } from "@material-ui/core";
import { IoMdTrash } from "@react-icons/all-files/io/IoMdTrash";
import { AiFillPlusCircle } from "@react-icons/all-files/ai/AiFillPlusCircle"
import firebase from 'firebase';
import config, { firebaseInit } from '../../config';
import { deleteCard, getMobilePayments, suscribe } from '../../api/content';
import DeleteMessageModal from './DeleteMessageModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutFormApplePay from './CheckoutFormApplePay';
// console.log('config.stripePublicKey', config.stripePublicKey);
//const stripePromise = loadStripe('pk_test_ZTWdtOJ6oAJkiGMYoq38V3RJ00XhpevSro');
const stripePromise = loadStripe('pk_live_Pg5X40Lr2lLS9mrQYuXVPDad001Ipi1T4Y');
const profileUri = config.serverUri

const PaymentMethodModal = ({ show, onClose, newPaymentMethod, message, code, reference, setIsApplePay }) => {

    const [token, setToken] = React.useState("")
    const [paymentInfo, setPaymentInfo] = React.useState({
        paymentMethods: [],
    })
    const [error, setError] = React.useState("")
    const [isSocioPremium, setIsSocioPremium] = React.useState(null)
    const [methodId, setMethodId] = React.useState("")
    const [userName, setUserName] = React.useState("")
    const [selectedMethod, setSelectedMethod] = React.useState(false)
    const [showDeleteMessage, setShowDeleteMessage] = React.useState(null)
    const [cardInfo, setCardInfo] = React.useState(null)

    React.useEffect(() => {
        firebaseInit(firebase)
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                user.getIdToken(true).then(token => {
                    //console.log("token")
                    //console.log(token)
                    setToken(token)
                    getMobilePayments(token).then(result => {
                        setPaymentInfo(result)
                        //console.log(result)
                    })
                        .catch(error => {
                            setError("nose ha podido cargar tu perfil de pagos intentalo nuevamente.")
                        })
                })
                    .catch(error => {
                        console.log(error)
                    })

                fetch(`${profileUri}/users/?email=${user.email}`)
                    .then(response => {
                        response.json().then(data => {
                            if (data[0]) {
                                // console.log(data[0].nombre, data[0].apellido)
                                setUserName(`${data[0].nombre} ${data[0].apellido}`)
                                fetch(`${profileUri}/users-suscriptions/?userId=${data[0].id}`)
                                    .then(responseNest => {
                                        responseNest.json().then(dataNest => {
                                            dataNest[0] && dataNest[0].suscriptionId === 'shift-socios' ? setIsSocioPremium(true) : setIsSocioPremium(false)
                                        })
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })
                            }
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                setToken(null)
            }
        })
    }, [])

    const handleSuscribe = () => {
        //console.log(token)
        //console.log(methodId)
        if (!methodId) {
            setError("No selecionaste algun método de pago")
            setTimeout(() => {
                setError('')
            }, 2000)
        } else {
            measurePurchases()
            //
            suscribe(token, methodId, reference, code)
                .then(suscription => {
                    //console.log(suscription)
                    message(true)
                })
                .catch(error => {
                    console.log(error)
                    setError(error)
                })

        }

    }

    const handleDeleteCard = (paymentMethod) => {

        setCardInfo(paymentMethod)
        setShowDeleteMessage(true)
        // deteleMessage([true,paymentMethod])
        // console.log(deleteConfirmation)
        //

    }

    const handleSelected = (id) => {
        if (!selectedMethod) {
            setMethodId(id)
        } else {
            setMethodId("")
        }
        setSelectedMethod(!selectedMethod)
    }

    const handleDeleteConfirmation = (card) => {
        setShowDeleteMessage(false)
        removeCard(card)
    }

    const removeCard = (paymentMethod) => {
        deleteCard(token, paymentMethod).then(card => {

            let filteredMethods = paymentInfo.paymentMethods.filter(
                item => item.id !== paymentMethod.id
            )
            setPaymentInfo({ ...paymentInfo, paymentMethods: filteredMethods })
            console.log(paymentInfo)
        })
            .catch(error => {
                console.log(error)
            })
    }

    const measurePurchases = () => {
        
        const orderid = 'order-123';
        const orderTotal = 99.00;
        const product = [
            {
                id:'product-1',
                name:'suscripcion shift online',
                price:99.00,
                quatity:1
            }
        ];
          
        const transaction = {
            transaction_id:orderid,
            value:orderTotal,
            currency:"MXN",
            items:product
        };

        if(window.gtag){
            window.gtag('event', 'purchase', transaction);
          }
    }

    return (
        <div>
            <Modal open={show} onClose={onClose}>
                <div className='bg-white  mx-auto rounded-lg px-5 pt-8 pb-4  overflow-y-auto h-5/6 w-3/4 md:w-2/4 md:h-5/6 top-12 flex flex-col modal-pago-nuevo'>
                    <p className='text-center text-xl text-sc-black font-bold'>Suscripción Digital Shift</p>
                    <div className='flex justify-between mt-1'>
                        <div>
                            <p className='m-0 p-0'>Costo: $99.00 mensuales</p>
                        </div>
                    </div>
                    <p className='text-center text-xl text-sc-black font-bold mt-2'>Mis tarjetas</p>
                    <div className='border border-sc-typo-titulo rounded-sm  flex-col mt-1 card-content' >
                        {
                            (paymentInfo.paymentMethods && paymentInfo.paymentMethods.length) > 0 ? (
                                paymentInfo.paymentMethods.map((method) => (
                                    <div className={`flex justify-between  mt-2 p-0 ${selectedMethod && methodId === method.id ? "border-4 bg-gray-200" : " "}`} >
                                        <div className=' pl-4 pt-2 -mb-6 cursor-pointer w-4/5' onClick={() => { handleSelected(method.id) }}>
                                            <p className='text-sc-black p-0 m-0'>TCD *** {method.card.last4}</p>
                                            <p className='pt-0 mt-0' style={{ fontSize: 12 }}>Nombre del titular: {userName}</p>
                                        </div>
                                        <div className='text-red-600 pt-2 pr-4 -mb-6 w-2/5 cursor-pointer' onClick={() => { handleDeleteCard(method) }}>
                                            <IoMdTrash className='mx-auto' size={20} />
                                            <p className='text-center text-sm'>Borrar</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='my-4 '>
                                    <p className='text-center text-sm font-semibold'>No tienes ningún método de pago.</p>
                                </div>
                            )

                        }
                        <div className='text-sc-black flex justify-end mt-4'>
                            <div className='cursor-pointer flex justify-end mt-4' onClick={() => newPaymentMethod(true)}>
                                <AiFillPlusCircle className='mt-1' size={25} />
                                <p className='ml-2 text-base  mr-3'>Agregar método de pago</p>
                            </div>
                        </div>
                        <div className='ml-2 -mb-7'>
                            <p className='text-sc-black text-center font-bold ' style={{ fontSize: 12 }}>
                                Al presionar continuar se procede con el método de pago seleccionado y se genera la suscripción a SHIFT
                            </p>
                        </div>
                    </div>
                    <div className='text-red-500 text-center text-sm'>{error}</div>
                    <div className=' flex justify-between mt-4'>
                        <div className='pl-7 pr-7 md:pl-12 md:pr-12 cursor-pointer' onClick={() => onClose()}>Cancelar</div>
                        <div className='border border-sc-black rounded-full pl-7 pr-7 md:pl-12  md:pr-12 cursor-pointer mb-4' onClick={() => handleSuscribe()}>Continuar</div>
                    </div>
                    <Elements stripe={stripePromise}>
                        <CheckoutFormApplePay suscriptionId={'6199af61e05a441d7ea2c6a4'} amount={98.99} code={''} message={message} setIsApplePay={setIsApplePay} />
                    </Elements>
                </div>

            </Modal>
            <DeleteMessageModal
                show={showDeleteMessage}
                onClose={() => { setShowDeleteMessage(false) }}
                deleteConfirmation={(value) => { handleDeleteConfirmation(value) }}
                cardInfo={cardInfo}
            />
        </div>

    )
}

export default PaymentMethodModal;
