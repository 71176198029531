import React from 'react'
import {Modal} from "@material-ui/core"
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft"
import {IoClose} from "@react-icons/all-files/io5/IoClose"
import { StaticImage } from "gatsby-plugin-image"
import InputTexfieldRegex from '../forms/InputTexfieldRegex';
import PersonIcon from '@material-ui/icons/Person';
import {BsPersonFill} from '@react-icons/all-files/bs/BsPersonFill'
import { IoMdCard } from "@react-icons/all-files/io/IoMdCard"
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { RiErrorWarningLine } from "@react-icons/all-files/ri/RiErrorWarningLine"
import firebase from 'firebase';
import { getMobilePayments, saveNewCard, suscribe } from '../../api/content'
import {firebaseInit} from '../../config';
import Spinner from '../Spinner'



import {
    validateEmail,
    validateNames,
    validateNumbers
} from "../forms/helper/validationsRegexFuncs.js"
import SCButton from '../buttons/SCButton';
import { Message } from '@material-ui/icons'
import { check } from 'prettier'

const NewPaymentFormModal = ({ show, onClose,back,cvvHelp, messageOk, code, reference }) =>{

    const [cardInfo, setCardInfo] = React.useState({
        cardHolderName: "",
        cardNumber: "",
        cvv: "",
        expirationYear: 0,
        expirationMonth: 0
    })
    const [paymentInfo, setPaymentInfo] = React.useState({
        paymentMethods:[],
    })


    const updateProperty = (property, value) => {
        if (property == 'cardNumber' && value.length <= 19) setCardInfo({ ...cardInfo, [property]: value })
        if (property == 'cardHolderName' && value.length <= 25) setCardInfo({ ...cardInfo, [property]: value })
    }
    const [expirationString, setExpirationString] = React.useState("")
    const [error, setError] = React.useState("")

    const [termsOfServiceAccepted, setTermsOfServiceAccepted] = React.useState(false)
    const [saveMyCard, setSaveMyCard] = React.useState(false)
    const [token, setToken] = React.useState("")
    const [cardDuplicate, setCardDuplicate] = React.useState(0);
    const [spinnerLoading, setSpinnerLoading] =React.useState(false);

    const [expirationHasSlash, setExpirationHasSlash] = React.useState(false)
    const numbers = /^\d+$/;

    React.useEffect(()=>{
        firebaseInit(firebase)
        firebase.auth().onAuthStateChanged(user=>{
            if(user){
                user.getIdToken(true).then(token => {
                    // console.log("token")
                    // console.log(token)
                    setToken(token)
                    getMobilePayments(token).then(result=>{
                        setPaymentInfo(result)
                    })
                    .catch(error =>{
                        console.log("datos sin cargar")
                    })
                  })
                  .catch(error =>{
                    console.log(error)
                  })
            }else{
                setToken(null)
            }
        })
    },[])

    
    const handleExpirationChange = (value) => {
        if (numbers.test(value) || value.includes("/")) {
            if (value.length < 2) {
                setExpirationString(value)
            } else {
                if (value.length <= 5) {
                    let result = value
                    if (!value.includes("/")) {
                        result = [value.slice(0, 2), "/", value.slice(3)].join('')
                        setExpirationHasSlash(!expirationHasSlash)
                    }
                    if (result.length == 3 && expirationHasSlash) {
                        value = result.split('/').join('')
                        result = result.split('/').join('')
                        setExpirationHasSlash(!expirationHasSlash)
                    }
                    setExpirationString(result)
                }
            }
            let expirationMonth = value.length >= 2 ? parseInt(value.slice(0, 2)) : 0
            let expirationYear = value.length == 5 ? parseInt(value.slice(3, 5)) : 0
            setCardInfo({ ...cardInfo, expirationMonth, expirationYear })
        }
        if (value == "") {
            setExpirationString('')
        }
    }

    const handleChange = (property, value) => {
        if (numbers.test(value)) {
            setCardInfo({ ...cardInfo, [property]: value })
        }
        if (value == "") {
            setCardInfo({ ...cardInfo, [property]: '' })
        }
    }


    const saveCard = ()=>{
        if(validate()){
            setSpinnerLoading(true)
            saveNewCard(token, {
                number:cardInfo.cardNumber,
                name: cardInfo.cardHolderName,
                cvv: cardInfo.cvv,
                expirationMonth: cardInfo.expirationMonth,
                expirationYear: cardInfo.expirationYear
            }).then(paymentMethod =>{
                //console.log(paymentMethod)
                // message()
                setCardInfo({cardHolderName: "",
                cardNumber: "",
                cvv: "",
                expirationYear: 0,
                expirationMonth: 0})
                setExpirationString("")
                if(paymentMethod){
                    measurePurchases()
                    //
                    suscribe(token,paymentMethod.id,reference,code)
                    .then(suscription =>{
                        //console.log(suscription)
                        messageOk(true)
                        setSpinnerLoading(false)
                    })
                    .catch(error =>{
                        setError(error)
                        setSpinnerLoading(false)
                    })
                }
                
            })
            .catch(error => {
                console.log(error)
                setError(error)
                setSpinnerLoading(false)
            })
            
        }
        
    }

    const checkDuplicate = () => {
        
        if(paymentInfo.paymentMethods && paymentInfo.paymentMethods.length > 0){
            paymentInfo.paymentMethods.map((method) =>{
                let experitionYear = parseInt(`20${cardInfo.expirationYear}`)
                if(cardInfo.cardNumber.slice(-4) === method.card.last4 &&
                cardInfo.expirationMonth === method.card.exp_month &&
                experitionYear === method.card.exp_year){
                    console.log("tarjeta duplicada")
                    setCardDuplicate(1)
                    console.log(cardDuplicate)
                }else{
                    setCardDuplicate(0)
                }
            })
    }
    }

    const validate = () => {
        if (!cardInfo.cardHolderName) {
            setError("Ingresa el nombre del titular de la tarjeta")
            return false;
        }
        if (!cardInfo.cardNumber) {
            setError("Ingresa tu número de tarjeta")
            return false;
        }

        if (parseInt(cardInfo.cardNumber) <= 15 && parseInt(cardInfo.cardNumber) >= 19) {
            setError("Número de tarjeta no válido")
            return false;
        }

        if (cardInfo.expirationMonth === 0 || cardInfo.expirationYear === 0) {
            setError("Ingresa la fecha de expiración de tu tarjeta")
            return false;
        }

        if (cardInfo.expirationMonth > 12 || cardInfo.expirationMonth === 0) {
            setError("Mes de expiración no valido")
            return false;
        }
        let currentYear = parseInt(new Date().getFullYear().toString().slice(2, 4))
        if (cardInfo.expirationYear < currentYear) {
            setError(`El año de expiración de tu tarjeta no puede ser menor a ${currentYear}`)
            return false;
        }
        if (currentYear === cardInfo.expirationYear) {
            let currentMonth = new Date().getMonth() + 1
            if (cardInfo.expirationMonth < currentMonth) {
                setError("La fecha de tu tarjeta ha expirado")
                return false;
            }
        }
        if (!cardInfo.cvv) {
            setError("Ingresa el código de verificación de tu tarjeta")
            return false;
        }
        if(cardInfo.cvv.length < 3 ){
            console.log(cardInfo.cvv)
            setError("El codigo de verificacion es demasiado corto")
            return false;
        }
        if(1===1){
            checkDuplicate()
            console.log(cardDuplicate)
            if(cardDuplicate === 1){
                setError("Tarjeta ya registrada")
                return false
            }
        }
        setError("");
        return true;
    }

    const measurePurchases = () => {
        
        const orderid = 'order-123';
        const orderTotal = 99.00;
        const product = [
            {
                id:'product-1',
                name:'suscripcion shift online',
                price:99.00,
                quatity:1
            }
        ];
          
        const transaction = {
            transaction_id:orderid,
            value:orderTotal,
            currency:"MXN",
            items:product
        };

        if(window.gtag){
            window.gtag('event', 'purchase', transaction);
          }
    }

    return(
        <Modal open={show} onClose={onClose}>
            <div className='bg-white text-sc-black relative mx-auto rounded-lg px-5 pt-8 pb-4  overflow-y-auto h-5/6 w-3/4 md:w-2/4 md:h-full bottom-10 mt-5 flex flex-col'>
                <div className='flex justify-between'>
                    <div className='cursor-pointer' onClick={()=>back()}>
                        <FaArrowLeft  size={25} color="#002821"></FaArrowLeft>
                    </div>
                    <p className='text-lg font-bold '>Agregar nueva tarjeta</p>
                    <div className='cursor-pointer' onClick={()=>onClose()}>
                        <IoClose size={25} color="#002821"></IoClose>
                    </div>
                </div>
                <div className='border-2 border-sc-primary pl-10 pr-2 pt-2 pb-1 '>
                    <p className='text text-sm text-sc-black font-bold'>Datos de la suscripción:</p>
                    <div className='flex justify-between mb-2'>
                        <div className=''>
                            <p className='text text-sm text-sc-typo-titulo m-0 p-0'>Suscripción digital Shift</p>
                            <p className='text text-sm text-sc-typo-titulo m-0 p-0'>7 dias gratis, después $99.00 mensuales.</p>
                        </div>
                        <div className=''>
                        <StaticImage
                            width={96}
                            src="../../images/logo_black.png"
                            alt="Shift Logo">
                        </StaticImage>
                        </div>
                    </div>
                </div>
                <div className='mt-6 mx-2'>
                    <p className='text-sc-black text-sm font-bold'>Ingresar detalles</p>
                    <div className='w-full my-1'>
                        <InputTexfieldRegex
                        className="w-full"
                        onChange={cardInfo.cardHolderName}
                        setOnChange={updateProperty}
                        property={"cardHolderName"}
                        validateFunc={validateNames}
                        placeHolder={"Nombre(s)"}>
                        <BsPersonFill className='mb-0' size={20} style={{color:"#6B6B6B"}}/>
                        </InputTexfieldRegex>
                    </div>
                    <div className='w-full my-1'>
                    <InputTexfieldRegex
                            className="w-full"
                            onChange={cardInfo.cardNumber}
                            setOnChange={updateProperty}
                            property={"cardNumber"}
                            validateFunc={validateNumbers}
                            placeHolder={"Número de la tarjeta"}
                            type="int">
                            <IoMdCard style={{ color: "#6B6B6B" }} />
                        </InputTexfieldRegex>
                    </div>
                    <div className='w-full flex justify-between my-1 '>
                        <div className='w-5/12'>
                            <TextField
                                className="w-full"
                                placeholder="MM/AA"
                                value={expirationString}
                                size="small"
                                onChange={(e) => {handleExpirationChange(e.target.value)}}
                                InputProps={{
                                    startAdornment:(
                                        <InputAdornment position ="start">
                                            <IoMdCard style = {{color: "#6B6B6B"}}/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div className='w-5/12 '>
                        <TextField
                                className="w-full"
                                placeholder="CVV"
                                type="password"
                                value={cardInfo.cvv}
                                size="small"
                                onChange={(e) => {
                                    if (e.target.value.length <= 4) {
                                        handleChange("cvv", e.target.value)
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IoMdCard style={{ color: "#6B6B6B" }} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (<InputAdornment position="end"><a href='#' onClick={()=>cvvHelp()}><RiErrorWarningLine style={{ fontSize: '28px', cursor: 'pointer' }} /> </a></InputAdornment>)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='mt-2 mx-2'>
                    <p className='text text-xs text-sc-typo-titulo m-0 p-0' >Al presionar guardar se valida que los datos  de tu tarjeta son correctos y se genera la suscripción a SHIFT.</p>
                    <p className='text text-xs text-sc-typo-titulo m-0 p-0' >El cobro se realizará después del periodo de prueba.</p>
                </div>
                <div className='justify-center mx-14 mt-4'>
                    <SCButton className='mt-4'children={"PAGAR"} onClick={()=>saveCard()}></SCButton>
                </div>
                {
                        error ? <div className="mt-8 flex justify-center items-center w-full">
                            <div className="text-red-500">{error}</div></div> : null
                    }
                    {spinnerLoading ?
                        <div className="mt-4 flex justify-center items-center w-full">
                            <Spinner 
                                size={25}
                         />
                         </div>
                    :null}
                <div className='flex justify-center mt-4 cursor-pointer' onClick={()=>onClose()}>Cancelar</div>
                
                
            </div>
        </Modal>
    )
}

export default NewPaymentFormModal;