import React from 'react';
import { Modal } from "@material-ui/core";
import SCButton from '../buttons/SCButton';
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle';
import { IoClose } from "@react-icons/all-files/io5/IoClose";

const SubscriptionMessageModal = ({ show, onClose, isApplePay }) => {

    return (
        <Modal open={show} onClose={onClose}>
            <div className='bg-white  mx-auto rounded-lg px-5 pt-8 pb-4  overflow-y-auto w-4/5 md:w-2/5 min-h-min mt-28 flex flex-col'>
                <div className='flex justify-end mt-0 cursor-pointer' onClick={() => onClose()}>
                    <IoClose size={25} color="#002821"></IoClose>
                </div>
                <div className='border-b-2 border-sc-body  justify-center  flex'>
                    <FaCheckCircle className='mt-1 mb-2' size={20} color='#232231' />
                    <p className='text text-sc-primary font-bold text-base ml-4 mb-0' >¡Ya eres premium!</p>
                </div>

                <p className='mt-2 mb-0 text-center text-sm'>Ya puedes gozar de todos los beneficios de SHIFT</p>
                {/* {
                    !isApplePay &&
                    <p className='text-sm text-center text-sc-typo-titulo font-bold '>El cobro se realizará después de un periodo de 7 días de prueba</p>
                } */}
                <div className='justify-center mx-14 mt-2'>
                    <SCButton onClick={() => onClose()} children={"Aceptar"}></SCButton>
                </div>
            </div>
        </Modal>
    )
}

export default SubscriptionMessageModal;
